<template>
  <div>
    <portal
        v-if="expandedSections.length === 0 && model.number"
        to="page-title"
    >
      {{ $t('Invoice') }} #{{ model.number }},&nbsp;@
      <router-link :to="`/accounts-payable/vendors/${selectedVendor.id}/invoices`">
        {{ selectedVendor.code }}
        <span v-if="selectedVendor.name">({{ selectedVendor.name }})</span>
      </router-link>
    </portal>
    <InvoiceWarnings
        :invoice="model"
        :selected-vendor="selectedVendor"
        :invoice-id="existingInvoiceId"
        :invoice-already-exists="!!existingInvoiceId"
    />
    <base-form :loading="loading"
               :show-back="showBack"
               :show-cancel="showBack"
               :can-create-another-entity="!model.id"
               :save-text="$t('Create invoice')"
               :update-text="$t('Update invoice')"
               :submit-disabled="!!existingInvoiceId || gridContext.loading"
               :sticky-header="expandedSections.length === 0"
               entity="Invoice"
               submit-button-type="button"
               grid-classes="grid grid-cols-8 gap-x-3"
               layout="vertical"
               ref="baseForm"
               @cancel="onCancel"
               @submit="onSubmit"
    >
      <template #header>
        <el-collapse v-model="expandedSections"
                     class="col-span-8">
          <base-collapse-section key="form-header"
                                 name="form-header"
                                 class="-m-6 mb-6"
                                 has-background
          >
            <template #title>
              <div class="flex w-full items-center justify-between ml-2">
                <InvoiceFormHeader :invoice="model"/>
                <div class="mx-2">
                  {{ $t('Amount') }}:&nbsp;
                  {{ $formatPrice(model.gross_amount) }}
                </div>
              </div>
            </template>
            <InvoiceProcessingInput
              v-if="$route.query.beta"
              class="print:hidden"
              :vendor-id="model.vendor_id"
              @parsed="onInvoiceProcessed"
            />
            <div class="grid grid-cols-8 gap-x-4 pt-4 px-4">
              <VendorSelect v-model="model.vendor_id"
                            :disabled="!!model.id"
                            :edit-entity="!model.id"
                            :initial-value="get(invoice, 'relationships.vendor') || selectedVendor"
                            rules="required"
                            id="vendor"
                            class="col-span-8 md:col-span-2"
                            @change="onVendorChanged"
              />

              <InvoiceNumberInput
                v-model="model.number"
                :model="model"
                @blur="validateInvoice"
              />

              <base-input v-model="model.description"
                          :label="$t('Description')"
                          :placeholder="$t('Description')"
                          id="description"
                          rules="max:150"
                          class="col-span-8 md:col-span-3"
              />
              <base-input v-model="model.gross_amount"
                          :label="$t('Amount')"
                          :placeholder="$t('Amount')"
                          :min="-9999999999.99"
                          :step="1"
                          :max="9999999999.99"
                          id="gross_amount"
                          type="number"
                          format="price"
                          rules="required|max_value:9999999999.99|min_value:-9999999999.99"
                          class="col-span-8 md:col-span-2"
              />
              <div class="col-span-8">
                <h5 class="form-section-title">
                  {{ $t('Dates & Discount') }}
                </h5>
              </div>
              <base-date-picker v-model="model.date"
                                :label="$t('Date')"
                                :placeholder="$t('Date')"
                                id="date"
                                rules="required"
                                class="col-span-8 md:col-span-2"
                                @change="onChangeDate"
              />
              <base-date-picker v-model="model.due_date"
                                :label="$t('Due Date')"
                                :placeholder="$t('Due Date')"
                                id="due_date"
                                rules="required"
                                class="col-span-8 md:col-span-2"
              />
              <base-date-picker v-model="model.discount_date"
                                :label="$t('Discount Date')"
                                :placeholder="$t('Discount Date')"
                                id="discount_date"
                                rules="required"
                                class="col-span-8 md:col-span-2"
              />
              <base-input v-model="model.discount_percent"
                          :label="$t('Discount')"
                          :placeholder="$t('Discount')"
                          id="discount"
                          :min="0"
                          :max="100"
                          :step="0.00001"
                          type="number"
                          format="percent"
                          rules="max_value:100|min_value:0"
                          class="col-span-8 md:col-span-1"
              />
              <base-input v-model="model.retention_percent"
                          :label="$t('Retention')"
                          :placeholder="$t('Retention')"
                          id="retention"
                          :min="0"
                          :max="100"
                          :step="0.01"
                          type="number"
                          format="percent"
                          rules="max_value:100|min_value:0"
                          class="col-span-8 md:col-span-1"
              />
              <div class="col-span-8 md:col-span-2">
                <invoice-purchase-order
                  v-model="model.purchase_order_id"
                  :disabled="!model.vendor_id"
                  :vendor-id="model.vendor_id"
                  :vendor="selectedVendor"
                  :invoice="model"
                  @on-select-items="onSelectPoItems"
                  @on-clear-items="onClearPoItems"
                />
              </div>
              <div class="col-span-8 md:col-span-1">
                <base-switch v-model="model.close_po_after"
                             :label-info="$t('Close P.O. After')"
                             :disabled="!model.purchase_order_id"
                             id="close_after"
                             vertical
                />
              </div>
              <div class="col-span-8 md:col-span-1">
                <base-switch :value="model.status === 'no_post'"
                             :label-info="$t('No Post')"
                             :disabled="!authorizedToUpdateStatus"
                             @input="onNoPostChange"
                />
              </div>
              <base-select v-model="model.type"
                           :label="$t('Type')"
                           :placeholder="$t('Type')"
                           :options="invoiceTypeOptions"
                           id="type"
                           class="col-span-8 md:col-span-1"
              />
              <template v-if="model.type === 'manual'">
                <div class="col-span-8">
                  <h4 class="form-section-title">
                    {{ $t('Check Information') }}
                  </h4>
                </div>
                <BankSelect
                  v-model="model.bank_id"
                  :name="$t('Bank')"
                  :label="$t('Bank')"
                  :used-for="BankUsedInTypes.AccountsPayable"
                  id="bank_id"
                  rules="required"
                  class="col-span-8 md:col-span-1"
                />
                <CheckNumberInput
                  v-model="model.payment_number"
                  :bankId="model.bank_id"
                  id="payment_number"
                  rules="required"
                  class="col-span-8 md:col-span-1"
                />
                <base-date-picker v-model="model.payment_date"
                                  :label="$t('Check Date')"
                                  :placeholder="$t('Check Date')"
                                  id="payment_date"
                                  rules="required"
                                  class="col-span-8 md:col-span-2"
                />
              </template>
            </div>
          </base-collapse-section>
        </el-collapse>
      </template>
      <InvoiceEntries
          ref="entriesTable"
          :data="model"
          :vendor="selectedVendor"
          class="col-span-8"
          @on-collapse-form-header="collapseFormHeader"
      />
      <FileAttachments
          ref="attachments"
          :entity-id="entityId"
          entity="invoices"
          class="col-span-8 mt-8"
      />
    </base-form>
  </div>
</template>
<script>
  import axios from 'axios'
  import format from 'date-fns/format'
  import addDays from 'date-fns/addDays'
  import { BankUsedInTypes, resourceStatuses, vendorDaysTerms } from '@/enum/enums'
  import { invoiceTypeOptions } from '@/modules/accounts-payable/enum/invoice'
  import InvoiceWarnings from '@/modules/accounts-payable/components/invoice/InvoiceWarnings'
  import InvoiceFormHeader from '@/modules/accounts-payable/components/invoice/InvoiceFormHeader'
  import InvoicePurchaseOrder from '@/modules/accounts-payable/components/invoice/InvoicePurchaseOrder'
  import InvoiceEntries from '@/modules/accounts-payable/components/invoice/InvoiceEntries.vue';
  import { validateAgDataTable } from '@/components/ag-grid/tableUtils'
  import { RestifyResources } from "@/components/form/util";
  import InvoiceNumberInput from "@/modules/accounts-payable/components/invoice/InvoiceNumberInput.vue";
  import CheckNumberInput from "@/components/common/CheckNumberInput.vue";
  import config from "@/modules/common/config";
  import InvoiceProcessingInput from "@/modules/accounts-payable/components/invoice/InvoiceProcessingInput.vue";
  import { gridContext } from "@/components/ag-grid/gridContext";
  import { delay } from "@/utils/utils";

  const emptyModel = {
    number: '',
    description: '',
    gross_amount: 0,
    close_po_after: false,

    type: 'normal',
    bank_id: null,
    payment_number: null,
    payment_date: null,
  }

  export default {
    components: {
      InvoiceProcessingInput,
      InvoiceNumberInput,
      InvoiceEntries,
      CheckNumberInput,
      InvoiceWarnings,
      InvoiceFormHeader,
      InvoicePurchaseOrder,
    },
    props: {
      invoice: {
        type: Object,
        default: () => ({}),
      },
      showBack: {
        type: Boolean,
        default: true,
      },
      collapseHeader: {
        type: Boolean,
      },
    },
    data() {
      return {
        resourceStatuses,
        invoiceTypeOptions,
        expandedSections: [],
        loading: false,
        parsing: false,
        loadVendor: false,
        selectedVendor: {},
        existingInvoiceId: null,
        purchaseOrderItemIds: [],
        entityId: null,
        model: {
          vendor_id: undefined,
          purchase_order_id: undefined,
          discount_percent: 0,
          retention_percent: 0,
          date: this.$now,
          due_date: this.$now,
          discount_date: this.$now,
          status: this.$settings(this.$modules.AP, 'default_invoice_status') || resourceStatuses.Pending,
          ...emptyModel,
        },
        gridContext,
      }
    },
    computed: {
      BankUsedInTypes() {
        return BankUsedInTypes
      },
      authorizedToUpdateStatus() {
        return [resourceStatuses.Pending, resourceStatuses.NoPost].includes(this.model.status)
      },
    },
    methods: {
      onClearPoItems() {
        this.$refs.entriesTable?.clearItems()
      },
      onSelectPoItems(items) {

        this.onClearPoItems([])
        this.purchaseOrderItemIds = items.map(item => item.id)

        this.$refs.entriesTable?.pushLineItems(items)
      },
      onNoPostChange(value) {
        value ? this.model.status = resourceStatuses.NoPost : this.model.status = resourceStatuses.Pending
      },
      async onVendorChanged() {

        if (this.model.purchase_order_id) {
          const confirmed = await this.$confirm({
            title: this.$t('Change Vendor'),
            description: this.$t('Changing the vendor will clear the purchase order items. Do you want to continue?'),
            buttonText: this.$t('Continue'),
          })

          if (!confirmed) {
            return
          }

          this.onClearPoItems(this.purchaseOrderItemIds)
        }

        this.$nextTick(async () => {
          await this.getVendorData()
          this.model.discount_percent = parseFloat(this.selectedVendor?.discount_percent).toFixed(2)
          this.model.purchase_order_id = ''
          this.computeDates()
        })
      },
      async onChangeDate() {
        await this.$nextTick()
        this.computeDates()
      },
      computeDates() {
        if (this.selectedVendor.due_date === vendorDaysTerms.DaysFromInvoiceDate && this.selectedVendor.due_date_days) {
          const due_date = addDays(new Date(this.model.date), this.selectedVendor.due_date_days)
          this.model.due_date = format(due_date, 'yyyy-MM-dd')
        } else {
          this.model.due_date = this.model.date
        }

        if (this.selectedVendor.discount_date === vendorDaysTerms.DaysFromInvoiceDate && this.selectedVendor.discount_date_days) {
          const discount_date = addDays(new Date(this.model.date), this.selectedVendor.discount_date_days)
          this.model.discount_date = format(discount_date, 'yyyy-MM-dd')
        } else {
          this.model.discount_date = this.model.date
        }

        this.validateInvoice()
      },
      async getVendorData() {
        try {
          this.loadVendor = true
          const { data } = await axios.get(`/restify/vendors/${this.model.vendor_id}`)
          this.selectedVendor = data?.attributes ?? {}
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          this.loadVendor = false
        }
      },
      async validateInvoice() {
        this.existingInvoiceId = null

        if (!this.model.vendor_id || !this.model.date || !this.model.number || this.model.id) {
          return
        }

        try {
          this.loading = true

          let { data } = await axios.get('/restify/invoices', {
            params: {
              status: resourceStatuses.Pending,
              vendor_id: this.model.vendor_id,
              number: this.model.number,
            },
          })
          data = data.filter(invoice => invoice.id !== this.model.id)
          this.existingInvoiceId = this.get(data, '[0].id', '')
        } catch (err) {
          this.loading = false
          console.warn(err)
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          this.loading = false
        }
      },
      onCancel() {
        return this.$router.push('/accounts-payable/invoices/pending')
      },
      refreshInvoiceFromStore() {
        this.$store.dispatch('accountsPayable/getInvoice', this.model.id)
      },
      async onSubmit() {
        try {
          const isInvalidData = await validateAgDataTable()
          if (isInvalidData) {
            return
          }

          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/invoices/${this.model.id}`, this.model)
            await this.$refs.entriesTable.storeProgress(this.model.id)
            this.$success(this.$t('Invoice updated'))

            this.refreshInvoiceFromStore()
            await this.$addSystemGeneratedNote({
              resourceName: RestifyResources.Invoices,
              resourceId: this.model.id,
              isEdit: true,
            })
          } else {
            const { data } = await axios.post('/restify/invoices', this.model)
            this.entityId = data.id
            this.model.id = data.id
            await this.$refs.entriesTable.storeProgress(data.id)
            debugger
            this.$success(this.$t('Invoice created'))
            await this.$addSystemGeneratedNote({
              resourceName: RestifyResources.Invoices,
              resourceId: data.id,
            })
            await this.$refs.attachments.triggerUpload()
            this.$createAnotherEntity ? this.$emit('create-another') : await this.$router.push('/accounts-payable/invoices/pending')
          }
        } catch (err) {
          this.loading = false
          // * in case an error occurs on store invoice entries - redirect to edit page
          // * in case an error occurs on store invoice - prevent redirect
          if (!this.invoice?.id && err?.config?.url !== '/restify/invoices' && this.model.id) {
            this.$info('The invoice was created, but an error occurred while storing invoice entries. Redirecting to edit page...')
            await delay(2000)
            await this.$router.push(`/accounts-payable/invoices/${this.model.id}/edit`)
          }

          console.warn(err)
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          this.loading = false
        }
      },
      focusOnVendorInput() {
        const vendorInput = document.querySelector('#vendor input')
        if (vendorInput) {
          vendorInput.focus()
        }
      },
      tryPrefillVendor() {
        const { vendor_id } = this.$route.query
        if (!vendor_id || this.data?.id) {
          return this.focusOnVendorInput()
        }
        this.model.vendor_id = vendor_id
        this.onVendorChanged()
      },
      async collapseFormHeader() {
        if (!this.expandedSections.length) {
          return
        }

        const isValidForm = await this.$refs.baseForm?.validate()

        if (!isValidForm) {
          return
        }

        this.expandedSections = []
      },
      async onInvoiceProcessed(model, entries) {
        if (!entries.length || !model) {
          return
        }
        this.model = {
          ...this.model,
          ...model
        }
        await this.$refs.entriesTable?.pushParsedLineItems(entries)
      }
    },
    mounted() {
      this.tryPrefillVendor()
      if (this.collapseHeader) {
        this.collapseFormHeader()
      }
    },
    watch: {
      invoice: {
        immediate: true,
        handler(value) {
          if (!value?.id && !this.collapseHeader) {
            return this.expandedSections = ['form-header']
          }
          this.model = {
            ...this.model,
            ...value.attributes,
          }
          this.selectedVendor = { ...value.relationships?.vendor?.attributes }
          this.collapseFormHeader()
        },
      },
    },
  }
</script>
